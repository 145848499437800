import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import Logo from "../../img/logo.png";
import "../../flaticon/flaticon.css";
import "./style.css";
import {
  FaFacebookF,
  FaGooglePlus,
  FaLinkedinIn,
  FaPinterestP,
  FaTwitter,
} from "react-icons/fa";

const Cta = () => {
  const onClick = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <footer className="footer-section">
        {/* Footer Top Start */}
        <div className="footer-top">
          <Container>
            <Row>
              <Col lg={4} md={6}>
                <div className="single-footer ">
                  <div className="footer-logo">
                    <Link to="/">
                      <img src={Logo} alt="logo" />
                    </Link>
                  </div>
                  <p>
                    Inteligencia Artificial Para mejorar la Eficiencia en el
                    Punto de Venta
                  </p>
                  <ul className="footer-social">
                    <li>
                      <Link to="/" onClick={onClick} className="tw">
                        <FaTwitter />
                      </Link>
                    </li>
                    <li>
                      <Link to="/" onClick={onClick} className="fb">
                        <FaFacebookF />
                      </Link>
                    </li>
                    <li>
                      <Link to="/" onClick={onClick} className="gp">
                        <FaGooglePlus />
                      </Link>
                    </li>
                    <li>
                      <Link to="/" onClick={onClick} className="pin">
                        <FaPinterestP />
                      </Link>
                    </li>
                    <li>
                      <Link to="/" onClick={onClick} className="ln">
                        <FaLinkedinIn />
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg={5} md={6}>
                <Row>
                  <Col lg={6} md={6}>
                    <div className="single-footer  ">
                      <h3>Ecosistema</h3>
                      <ul>
                        <li>
                          <Link
                            to="https://roboflow.com/"
                            onClick={() => onClick}
                          >
                            Roboflow
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="https://roboflow.com/train"
                            onClick={() => onClick}
                          >
                            Entrenamiento
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="https://roboflow.com/integrations"
                            onClick={() => onClick}
                          >
                            Integraciones
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="https://cloud.google.com/vision/docs/ocr?hl=es-419"
                            onClick={() => onClick}
                          >
                            OCR
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="https://blog.roboflow.com/"
                            onClick={() => onClick}
                          >
                            Blog
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col lg={6} md={6}>
                    <div className="single-footer  ">
                      <h3>Empresa</h3>
                      <ul>
                        <li>
                          <Link to="/" onClick={onClick}>
                            Sobre nosotros
                          </Link>
                        </li>
                        <li>
                          <Link to="/" onClick={onClick}>
                            Servicios
                          </Link>
                        </li>
                        <li>
                          <Link to="/" onClick={onClick}>
                            Documentación
                          </Link>
                        </li>
                        <li>
                          <Link to="/" onClick={onClick}>
                            T&C
                          </Link>
                        </li>
                        <li>
                          <Link to="/" onClick={onClick}>
                            Política de privacidad
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={3} md={6}>
                <div className="single-footer ">
                  <h3>Contacto</h3>
                  <ul className="footer-contact-info">
                    <li>Cerro el plomo 5931, Santiago, Chile</li>
                    <li>
                      <span>Email:</span>
                      <a href="mailto:contacto@bestore.ai">
                        contacto@bestore.ai
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* Footer Top End */}
        {/* Footer Bottom Start */}
        <div className="footer-bottom">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="copyright ">
                  <p>
                    &copy; 2024 <span>Bestore.ai</span>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* Footer Bottom End */}
      </footer>
    </>
  );
};

export default Cta;
