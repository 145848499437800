import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import setupImg from "../../img/setup.png";
import { Col, Container, Row } from "react-bootstrap";
import { LuArrowUpSquare, LuStar, LuCheckCircle2, LuHistory, LuList, LuRefreshCcw, LuSearch, LuTag, LuTerminal, LuWand2, LuFilter, LuCode2, LuRocket, LuWifiOff, LuScale, LuClipboardList, LuCheckSquare, LuKey, LuLineChart, LuNetwork } from "react-icons/lu";
import { SlRocket } from "react-icons/sl";
import { FaChartBar, FaChevronRight } from "react-icons/fa";
import { LuCircuitBoard } from "react-icons/lu";
import { MdGroups2 } from "react-icons/md";
import "./style.css";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function TabCustom() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const tabs = [0, 1, 2, 3, 4];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <section className="tab-section">
      <Box className="contain">
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Setup" icon={<FaChartBar />} {...a11yProps(0)} />
            <Tab label="Modelos" icon={<LuCircuitBoard />} {...a11yProps(1)} />
            <Tab
              label="Operación"
              icon={<LuNetwork />}
              {...a11yProps(2)}
            />
            <Tab label="Match & QA" icon={<SlRocket />} {...a11yProps(3)} />
            <Tab label="Dashboard" icon={<MdGroups2 />} {...a11yProps(4)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          {tabs.map((item, index) => {
            return (
              <TabPanel
                key={index}
                value={value}
                index={index}
                dir={theme.direction}
              >
                <Container className="contain">
                  {index === 0 && (
                    <Row>
                      <Col lg={6} md={6}>
                        <div className="left">
                          <img src={setupImg} alt="about" className="left-image" />
                        </div>
                      </Col>
                      <Col lg={6} md={6}>
                        <div className="rigth">
                          <h2 className="title">
                            Implementa tu modelo de IA en días y no meses
                          </h2>
                          <Row className="mt-20">
                            <Col lg={1} md={1} xs={1}>
                              <LuArrowUpSquare className="icon" />
                            </Col>
                            <Col lg={11} md={11} xs={11}>
                              <p className="text">
                                Define el servicio (OSA, Facing, Pricing
                                competitivo o Planograma), arma tu canasta de
                                productos y los PDVs a medir
                              </p>
                            </Col>
                          </Row>
                          <Row className="mt-20">
                            <Col lg={1} md={1} xs={1}>
                              <LuTag className="icon" />
                            </Col>
                            <Col lg={11} md={11} xs={11}>
                              <p className="text">
                                Sin necesidad de fotos de referencia, entrenamos
                                en muy corto plazo modelos de detección
                                automática de imágenes
                              </p>
                            </Col>
                          </Row>
                          <Row className="mt-20">
                            <Col lg={1} md={1} xs={1}>
                              <LuList className="icon" />
                            </Col>
                            <Col lg={11} md={11} xs={11}>
                              <p className="text">
                                Levantamos información en todos los canales:
                                moderno, tradicional, tiendas de conveniencia,
                                farmacias y mucho más
                              </p>
                            </Col>
                          </Row>
                          <Row className="mt-20">
                            <Col lg={1} md={1} xs={1}>
                              <LuSearch className="icon" />
                            </Col>
                            <Col lg={11} md={11} xs={11}>
                              <p className="text">
                                Tenemos presencia en todo Latam, principalmente
                                en México, Colombia, Perú, Uruguay, Argentina y
                                Chile
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  )}
                  {index === 1 && (
                    <Row>
                      <Col lg={6} md={6}>
                        <div className="left">
                          <img src="https://rp-public-files.s3.amazonaws.com/bestore/match-qa.gif" alt="about" className="left-image" />
                        </div>
                      </Col>
                      <Col lg={6} md={6}>
                        <div className="rigth">
                          <h2 className="title">
                            Entrenamos con IA todos los productos de tu canasta
                          </h2>
                          <Row className="mt-20">
                            <Col lg={1} md={1} xs={1}>
                              <LuNetwork className="icon" />
                            </Col>
                            <Col lg={11} md={11} xs={11}>
                              <p className="text">
                                Diseñado tanto para productos de categorías PGC
                                como perecibles (frutas, verduras, carnes, entre
                                otras)
                              </p>
                            </Col>
                          </Row>
                          <Row className="mt-20">
                            <Col lg={1} md={1} xs={1}>
                              <LuWand2 className="icon" />
                            </Col>
                            <Col lg={11} md={11} xs={11}>
                              <p className="text">
                                Optimizado para etiquetado ultrarrápido, tanto
                                para anotaciones manuales como{" "}
                                <strong>etiquetado asistido por IA</strong>
                              </p>
                            </Col>
                          </Row>
                          <Row className="mt-20">
                            <Col lg={1} md={1} xs={1}>
                              <LuTerminal className="icon" />
                            </Col>
                            <Col lg={11} md={11} xs={11}>
                              <p className="text">
                                <strong>
                                  Flujos de trabajos simplificados
                                </strong>{" "}
                                con asignación de trabajos, instrucciones de
                                etiquetado, revisión, notificaciones y métricas
                              </p>
                            </Col>
                          </Row>
                          <Row className="mt-20">
                            <Col lg={1} md={1} xs={1}>
                              <LuHistory className="icon" />
                            </Col>
                            <Col lg={11} md={11} xs={11}>
                              <p className="text">
                                <strong>Implementable</strong> en NVIDIA Jetson,
                                iOS, cámaras OAK, Raspberry Pi, el navegador o
                                cloud propio y puede ejecutarse en línea o sin
                                conexión en tiempo real
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  )}
                  {index === 2 && (
                    <Row>
                      <Col lg={6} md={6}>
                        <div className="left">
                          <img src="https://rp-public-files.s3.amazonaws.com/bestore/operation.gif" alt="about" className="left-image" />
                        </div>
                      </Col>
                      <Col lg={6} md={6}>
                        <div className="rigth">
                          <h2 className="title">
                            Tomamos cientos de fotos y videos en cada punto de
                            venta
                          </h2>
                          <Row className="mt-20">
                            <Col lg={1} md={1} xs={1}>
                              <LuRefreshCcw className="icon" />
                            </Col>
                            <Col lg={11} md={11} xs={11}>
                              <p className="text">
                                8x veces más rápido que cualquier auditoría
                                tradicional
                              </p>
                            </Col>
                          </Row>
                          <Row className="mt-20">
                            <Col lg={1} md={1} xs={1}>
                              <LuCheckCircle2 className="icon" />
                            </Col>
                            <Col lg={11} md={11} xs={11}>
                              <p className="text">
                                Ocupamos micro cámaras y hardwares de última
                                generación para ser indetectables en el
                                levantamiento de información
                              </p>
                            </Col>
                          </Row>
                          <Row className="mt-20">
                            <Col lg={1} md={1} xs={1}>
                              <LuStar className="icon" />
                            </Col>
                            <Col lg={11} md={11} xs={11}>
                              <p className="text">
                                Flexibilidad para trabajar con nuestra
                                aplicación e integración con Apps de terceros
                              </p>
                            </Col>
                          </Row>
                          <Row className="mt-20">
                            <Col lg={1} md={1} xs={1}>
                              <LuFilter className="icon" />
                            </Col>
                            <Col lg={11} md={11} xs={11}>
                              <p className="text">
                                Puedes ocupar nuestra red de profesionales o tu
                                propia fuerza en terreno para tomar fotos y
                                videos
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  )}
                  {index === 3 && (
                    <Row>
                      <Col lg={6} md={6}>
                        <div className="left">
                          <img src="https://rp-public-files.s3.amazonaws.com/bestore/models.gif" alt="about" className="left-image" />
                        </div>
                      </Col>
                      <Col lg={6} md={6}>
                        <div className="rigth">
                          <h2 className="title">
                            Mezcla perfecta entre la máquina y el humano
                          </h2>
                          <Row className="mt-20">
                            <Col lg={1} md={1} xs={1}>
                              <LuCode2 className="icon" />
                            </Col>
                            <Col lg={11} md={11} xs={11}>
                              <p className="text">
                                Usamos algoritmos avanzados para determinar
                                precios regulares, promociones, ubicación
                                correcta, caras exhibidas, entre otros
                              </p>
                            </Col>
                          </Row>
                          <Row className="mt-20">
                            <Col lg={1} md={1} xs={1}>
                              <LuRocket className="icon" />
                            </Col>
                            <Col lg={11} md={11} xs={11}>
                              <p className="text">
                                En casos donde los resultados de los modelos de
                                IA son menos concluyentes, generamos alertas
                                automáticas
                              </p>
                            </Col>
                          </Row>
                          <Row className="mt-20">
                            <Col lg={1} md={1} xs={1}>
                              <LuWifiOff className="icon" />
                            </Col>
                            <Col lg={11} md={11} xs={11}>
                              <p className="text">
                                Un analista de nuestro equipo revisa
                                inmediatamente el set de alertas y valida todos
                                los resultados
                              </p>
                            </Col>
                          </Row>
                          <Row className="mt-20">
                            <Col lg={1} md={1} xs={1}>
                              <LuScale className="icon" />
                            </Col>
                            <Col lg={11} md={11} xs={11}>
                              <p className="text">
                                La combinación de estos procesos genera una
                                confiablidad superior al 97%
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  )}
                  {index === 4 && (
                    <Row>
                      <Col lg={6} md={6}>
                        <div className="left">
                          <img src="https://rp-public-files.s3.amazonaws.com/bestore/dashboard.gif" alt="about" className="left-image" />
                        </div>
                      </Col>
                      <Col lg={6} md={6}>
                        <div className="rigth">
                          <h2 className="title">
                            Plataforma de resultados en línea y a la medida
                          </h2>
                          <Row className="mt-20">
                            <Col lg={1} md={1} xs={1}>
                              <LuClipboardList className="icon" />
                            </Col>
                            <Col lg={11} md={11} xs={11}>
                              <p className="text">
                                Visualización de datos en línea, análisis de
                                series históricas y reportes personalizados
                              </p>
                            </Col>
                          </Row>
                          <Row className="mt-20 ">
                            <Col lg={1} md={1} xs={1}>
                              <LuCheckSquare className="icon" />
                            </Col>
                            <Col lg={11} md={11} xs={11}>
                              <p className="text">
                                Respaldo fotográfico de cada producto y descarga
                                de datos en múltiples formatos
                              </p>
                            </Col>
                          </Row>
                          <Row className="mt-20">
                            <Col lg={1} md={1} xs={1}>
                              <LuLineChart className="icon" />
                            </Col>
                            <Col lg={11} md={11} xs={11}>
                              <p className="text">
                                Alertas vía email, whatsapp, slack
                              </p>
                            </Col>
                          </Row>
                          <Row className="mt-20">
                            <Col lg={1} md={1} xs={1}>
                              <LuKey className="icon" />
                            </Col>
                            <Col lg={11} md={11} xs={11}>
                              <p className="text">
                                Multiusuarios ilimitados para toda la
                                organización
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  )}
                </Container>
              </TabPanel>
            );
          })}
        </SwipeableViews>
        <Container className="footer-tab">
          <Row className="btn-center">
            <Col md={12}>
              <a href="/#contact-section" className="btn-demo">
                Solicitar Demo
                <span>
                  <FaChevronRight />
                </span>
              </a>
            </Col>
          </Row>
        </Container>
      </Box>
    </section>
  );
}
