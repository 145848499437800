import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaChevronRight } from "react-icons/fa";
import "../../flaticon/flaticon.css";
import "./style.css";

const Cta = () => {
  return (
    <>
      <section className="trial-section section_100">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="trial-box">
                <div className="section-title ">
                  <h2>
                    Obtén tu demo en menos de <span>60</span> minutos
                  </h2>
                  <p>
                    Sin formularios extensos o ingresando tarjetas de crédito
                  </p>
                </div>
                <a href="#contact-section" className="theme-btn-white ">
                  Solicita un demo
                  <span>
                    <FaChevronRight />
                  </span>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Cta;
