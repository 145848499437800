import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import "../../flaticon/flaticon.css";
import "./style.css";

const Services = () => {
  return (
    <>
      <section id="service-section" className="service-section section_100">
        <Container>
          <Row className="align-items-center">
            <Col lg={5} md={12}>
              <div className="section-title  ">
                <h2>
                  Servicios para <span>mejorar</span><br /> 
                  tu ejecución en PDV
                </h2>
              </div>
            </Col>
            <Col lg={7} md={12}>
              <div className="section-para">
                <p>
                  Enfócate en lo que realmente importa. Nuestra tecnología con Inteligencia Artificial 
                  mide y analiza todos los KPIs, desde la disponibilidad de un producto hasta el cumplimiento del planograma.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={4} md={6}>
              <div className="service-box">
                <div className="service-icon">
                  <i className="flaticon-chip" />
                </div>
                <div className="service-title">
                  <h3>Presencia de producto (OSA)</h3>
                </div>
                <div className="service-desc">
                  <p>
                    Si tu producto no está en la góndola, no se venderá. Comprueba la 
                    presencia de tus productos con respaldo fotográfico y mejora la venta perdida.
                  </p>
                </div>
                <div className="service-icon-bottom">
                  <i className="flaticon-chip" />
                </div>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="service-box  ">
                <div className="service-icon">
                  <i className="flaticon-picture" />
                </div>
                <div className="service-title">
                  <h3>Pricing competitivo</h3>
                </div>
                <div className="service-desc">
                  <p>
                    Descubre la dinámica de precios de tu competencia y
                    mejora tu estrategia implementando campañas con diferenciación y valor agregado.
                  </p>
                </div>
                <div className="service-icon-bottom">
                  <i className="flaticon-picture" />
                </div>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="service-box  ">
                <div className="service-icon">
                  <i className="flaticon-box" />
                </div>
                <div className="service-title">
                  <h3>Facing</h3>
                </div>
                <div className="service-desc">
                  <p>
                    La optimización de espacios es cada vez más exigente.
                    Gana visibilidad, genera acciones correctivas y asegura tu espacio en góndola.
                  </p>
                </div>
                <div className="service-icon-bottom">
                  <i className="flaticon-box" />
                </div>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="service-box  ">
                <div className="service-icon">
                  <i className="flaticon-mobile" />
                </div>
                <div className="service-title">
                  <h3>Participación en góndola (SOS)</h3>
                </div>
                <div className="service-desc">
                  <p>
                    Mide el nivel de participación (Share of Shelf) de tus categorías, 
                    compárate con tu competencia y acciona eficientemente para no perder espacio en góndola.
                  </p>
                </div>
                <div className="service-icon-bottom">
                  <i className="flaticon-mobile" />
                </div>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="service-box  ">
                <div className="service-icon">
                  <i className="flaticon-facial-recognition" />
                </div>
                <div className="service-title">
                  <h3>Planograma</h3>
                </div>
                <div className="service-desc">
                  <p>
                    Un layout inteligente maximiza las ventas. 
                    Monitorea el cumplimiento correcto del planograma diseñado e identifica áreas de mejora.
                  </p>
                </div>
                <div className="service-icon-bottom">
                  <i className="flaticon-facial-recognition" />
                </div>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="service-box  ">
                <div className="service-icon">
                  <i className="flaticon-video-player" />
                </div>
                <div className="service-title">
                  <h3>Tienda perfecta</h3>
                </div>
                <div className="service-desc">
                  <p>
                    Mide tu ejecución en PDV y asegúrate que tus productos en góndola 
                    estén disponibles en el lugar correcto, en el momento correcto y al precio correcto.
                  </p>
                </div>
                <div className="service-icon-bottom">
                  <i className="flaticon-video-player" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Services;
