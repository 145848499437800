import React, { useState } from "react";
import "./style.css";

const menus = [
  {
    id: 1,
    title: "Inicio",
    link: "#hero-section",
  },
  {
    id: 2,
    title: "Servicios",
    link: "#service-section",
  },
  {
    id: 3,
    title: "Contacto",
    link: "#contact-section",
  },
];

const MobileMenu = () => {
  const [MenuShow, SetMenuShow] = useState(false);

  return (
    <>
      <div className="responsiveMenu text-center">
        <nav
          id="mobileMenu"
          className={`mobileMenu ${MenuShow ? "active" : ""}`}
        >
          <ul className="responsivemenu">
            {menus.map((item) => {
              return (
                <li key={item.id} className="has-child">
                  <a 
                    href={item.link} 
                    onClick={() => SetMenuShow(!MenuShow)}
                  >
                    {item.title}
                  </a>
                </li>
              );
            })}
          </ul>
        </nav>

        <div
          className={`spinner-master ${MenuShow ? "active" : ""}`}
          onClick={() => SetMenuShow(!MenuShow)}
        >
          <div id="spinner-form" className="spinner-spin">
            <div className="spinner diagonal part-1"></div>
            <div className="spinner horizontal"></div>
            <div className="spinner diagonal part-2"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileMenu;
