import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { FaChevronRight } from "react-icons/fa";
import img1 from "../../img/about-shape.png";
import img2 from "../../img/rol.png";
import img3 from "../../img/convierte.png";

import "./style.css";

const About = () => {
  return (
    <>
      <section className="about-section">
        <div className="about-top-shape">
          <img src={img1} alt="about shape" />
        </div>
        <div className="about-bottom-shape">
          <img src={img1} alt="about shape" />
        </div>
        <div className="about-top section_100">
          <Container>
            <Row className="align-items-center">
              <Col lg={6} md={6}>
                <div className="about-left" data-aos="fade-right">
                  <h3>
                    Vende más y <br />  aumenta tu ROI con IA
                  </h3>
                  <p>
                    Con nuestra tecnología basada en Computer-vision y 
                    Deep learning, podemos hacer en minutos lo que 
                    tomaría días hacer manualmente, y con más precisión. 
                    Nuestros modelos de AI son capaces de detectar, 
                    clasificar, segmentar y predecir de forma exacta lo que 
                    ocurre en las góndolas de tiendas físicas.
                  </p>
                  <Link to="https://roboflow.com/models" className="theme-btn mt-4">
                    Leer más
                    <span>
                      <FaChevronRight />
                    </span>
                  </Link>
                </div>
              </Col>
              <Col lg={6} md={6}>
                <div className="about-right" data-aos="fade-left">
                  <img src={img2} alt="about" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="about-bottom section_b_100">
          <Container>
            <Row className="align-items-center">
              <Col lg={6} md={6}>
                <div className="about-right  " data-aos="fade-right">
                  <img src={img3} alt="about" />
                </div>
              </Col>
              <Col lg={6} md={6}>
                <div className="about-left" data-aos="fade-left">
                  <h3>
                    Convierte imágenes en Insights
                  </h3>
                  <p>
                  Bestore es una plataforma integral que recopila y analiza imágenes de tiendas en 
                  múltiples canales para luego transformarlas en información poderosa.
                  Procesamos cientos de miles de imágenes automáticamente para implementar eficiencias 
                  operativas y generar mayores ventas de forma rápida y escalable.
                  </p>
                  <Link to="/about" className="theme-btn mt-4">
                    Leer más
                    <span>
                      <FaChevronRight />
                    </span>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default About;
