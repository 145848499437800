import React from "react";
import VideoModal from "../../components/ModalVideo";
import { Col, Container, Row } from "react-bootstrap";
import shape from "../../img/her-pattarn-5.png";
import "../../flaticon/flaticon.css";
import "./style.css";

const Tour = () => {
  return (
    <>
      <section className="video-section section_t_100">
        <div className="video-bg-shape" />
        <div className="video-pattern">
          <img src={shape} alt="video patter" />
        </div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="section-title  ">
                <p>Toma un tour</p>
                <h2>
                  Haz que tu <span>negocio</span> sea más <br /> eficiente y
                  competitivo
                </h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="video-holder" data-aos="fade-up">
                <div className="video-inn">
                  <img src="https://rp-public-files.s3.amazonaws.com/bestore/tour.gif" alt="theater thumb" />
                  <VideoModal videoId="BqI0Q7e4kbk" channel="custom" url="https://rp-public-files.s3.amazonaws.com/bestore/final.mp4" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Tour;
