import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  FaChevronRight,
  FaEnvelope,
  FaGlobe,
  FaMapMarkerAlt,
} from "react-icons/fa";
import "./style.css";

const Contact = () => {
  return (
    <>
      <section id="contact-section" className="contact-form section_100">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="section-title  ">
                <h2>
                  Formulario de <span>contacto</span>
                </h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={8}>
              <div className="contact-form-wrapper ">
                <form>
                  <Row>
                    <Col lg={6}>
                      <div className="form-group">
                        <label htmlFor="name">Nombre</label>
                        <input
                          type="text"
                          className="input-field"
                          id="name"
                          placeholder="Ingresa tu Nombre"
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                          type="text"
                          className="input-field"
                          id="email"
                          placeholder="Ingresa tu Email"
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="form-group">
                        <label htmlFor="subjict">Asunto</label>
                        <input
                          type="text"
                          className="input-field"
                          id="subjict"
                          placeholder="Escribe tu Asunto"
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="form-group">
                        <label htmlFor="phone">Teléfono</label>
                        <input
                          type="text"
                          className="input-field"
                          id="phone"
                          placeholder="Ingresa tu número de Teléfono"
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="form-group button-area">
                        <label htmlFor="message">Mensaje</label>
                        <textarea
                          id="message"
                          className="input-field textarea"
                          placeholder="Escribe tu Mensaje"
                          defaultValue={""}
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="form-group button-area">
                        <button type="submit" className="theme-btn">
                          Enviar Mensaje
                          <span>
                            <FaChevronRight />
                          </span>
                        </button>
                      </div>
                    </Col>
                  </Row>
                </form>
              </div>
            </Col>
            <div className="col-lg-4 d-flex">
              <div className="address-area ">
                <h4 className="title">Información de contacto</h4>
                <ul className="address-list">
                  <li>
                    <p>
                      <FaMapMarkerAlt /> Cerro el plomo 5931, Santiago, Chile
                    </p>
                  </li>
                  <li>
                    <p>
                      <FaEnvelope /> contacto@bestore.ai
                    </p>
                  </li>
                  <li>
                    <p>
                      <FaGlobe />
                      www.bestore.ai
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Contact;
