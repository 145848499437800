import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaCheckSquare, FaStore } from "react-icons/fa";
import shape from "../../img/choose-shape.png";

import "./style.css";

const Features = () => {
  return (
    <>
      <section className="choose-section section_100">
        <div className="choose-shape-bg">
          <img src={shape} alt="choose" />
        </div>
        <Container>
          <Row className="align-items-center">
            <Col lg={6} sm={12}>
              <div className="choose-left  " data-aos="fade-right">
                <div className="section-title">
                  <h2>
                    Todos los canales que <span>necesites</span>
                  </h2>
                </div>
                <p>
                  Diariamente procesamos información de cientos de salas en múltiples canales.
                  Con más de 10 mil SKUs previamente entrenados, nuestro modelo de 
                  Inteligencia Artificial permite hacer hoy lo que otros no pueden
                </p>
                <ul>
                  <li>
                    <FaCheckSquare /> Ultra precisión +96%
                  </li>
                  <li>
                    <FaCheckSquare /> Control de calidad humano
                  </li>
                  <li>
                    <FaCheckSquare /> Data granular y accionable
                  </li>
                  <li>
                    <FaCheckSquare /> Setup menor a 7 días
                  </li>
                  <li>
                    <FaCheckSquare /> Flexible y escalable
                  </li>
                  <li>
                    <FaCheckSquare /> 24/7 soporte
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={6} sm={12}>
              <div className="choose-right">
                <Row>
                  <Col lg={6} sm={12} md={6}>
                    <div className="choose-box " data-aos="fade-up">
                      <div className="choose-icon">
                        <FaStore />
                      </div>
                      <h2>
                        <span className="counter">100+</span> <br />
                        Canal Moderno
                      </h2>
                      <p>Grandes cadenas y Cadenas Regionales</p>
                      <div className="choose-icon-bottom">
                        <FaStore />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} sm={12} md={6}>
                    <div className="choose-box " data-aos="fade-up">
                      <div className="choose-icon">
                        <FaStore />
                      </div>
                      <h2>
                        <span className="counter">300+</span> <br />
                        Canal Tradicional
                      </h2>
                      <p>Mayoristas, Distribuidoras y Ferias Libres </p>
                      <div className="choose-icon-bottom">
                        <FaStore />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} sm={12} md={6}>
                    <div className="choose-box  " data-aos="fade-up">
                      <div className="choose-icon">
                        <FaStore />
                      </div>
                      <h2>
                        <span className="counter">50+</span><br />
                        Tiendas de conveniencia
                      </h2>
                      <p>Tradicionales y Estaciones de servicio</p>
                      <div className="choose-icon-bottom">
                        <FaStore />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} sm={12} md={6}>
                    <div className="choose-box  " data-aos="fade-up">
                      <div className="choose-icon">
                        <FaStore />
                      </div>
                      <h2>
                        <span className="counter">60+</span><br />
                        Farmacias
                      </h2>
                      <p>Grandes cadenas y Perfumerías</p>
                      <div className="choose-icon-bottom">
                        <FaStore />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Features;
