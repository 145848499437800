import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import MobileMenu from "../../components/MobileMenu";
import logo from "../../img/logo.png";
import "./style.css";

const Header = (props) => {
  const [fix, setFix] = useState(false);

  function setFixed() {
    if (window.scrollY >= 50) {
      setFix(true);
    } else {
      setFix(false);
    }
  }

  window.addEventListener("scroll", setFixed);

  return (
    <>
      <header
        className={
          fix ? "animated header-area fixed-header" : "animated header-area"
        }
      >
        <Container>
          <Row>
            <Col lg={12}>
              <div className="d-flex align-items-center justify-content-between">
                <div className="logo-area  scroll-hide-logo">
                  <a href="#home-section">
                    <img className="logo-width" src={props.Logo} alt="logo"/>
                  </a>
                </div>
                <div className="logo-area scroll-show-logo">
                  <a href="#home-section">
                    <img className="logo-width" src={logo} alt="logo" />
                  </a>
                </div>
                <div className="menu-area d-flex justify-content-end align-items-center">
                  <div className="mobile-menu" />
                  <div className="main-menu">
                    <nav className="menu-box">
                      <ul className="nav-menu" id="mobile-menu">
                        <li>
                          <a href="#home-section">Inicio</a>
                        </li>
                        <li>
                          <a href="#service-section">
                            Servicios
                          </a>
                        </li>
                        {/* <li>
                          <a href="#pricing-section">Precios</a>
                        </li> */}
                        <li>
                          <a href="#contact-section">Contacto</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <MobileMenu />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </header>
    </>
  );
};

export default Header;
